.header {
  background-color: var(--iq-white);
  margin-bottom: 30px;
  position: relative;
  z-index: 4;
  padding-bottom: 180px;
}
.header__inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 25px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.header__inner--right {
  display: -webkit-box;
  display: flex;
}

.header__inner--right.mobile {
  -webkit-box-align: center;
  align-items: center;
}
.header__inner--logo {
  width: 235px;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__inner--logo img {
  max-width: 100%;
}

.header__login-menu {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.header__login-menu:after {
  content: "";
  height: 27px;
  width: 0.8px;
  background-color: var(--iq-black);
  display: block;
  margin: 0 35px 0 18px;
}

.header__login-menu a {
  color: var(--iq-darkgrey);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: left;
}

.header__login-menu a.highlighted,
.header__login-menu a:hover {
  color: var(--iq-orange);
}

.header__login-menu a:not(:last-child) {
  margin-right: 20px;
}

.header__login-menu.logged {
  position: relative;
}

.header__login-menu.logged ul {
  display: -webkit-box;
  display: flex;
  padding: 0;
  margin-bottom: 0;
}

.header__login-menu.logged ul li {
  list-style-type: none;
  position: relative;
}

.header__login-menu.logged ul li:not(:last-child) {
  margin-right: 14px;
}

.header__login-menu.logged > ul > li:last-child {
  margin-left: 5px;
  top: 10px;
}

.header__login-menu.logged ul li:nth-child(3):hover .header__dropdown.secondary,
.header__login-menu.logged ul li:nth-child(4):hover .header__dropdown.primary {
  display: block;
}

.header__login-menu.logged ul li.disabled > a {
  cursor: default;
}

.header__login-menu.logged ul li.disabled:hover > a {
  color: var(--iq-darkgrey);
}

.header__login-menu.logged ul li a {
  margin-right: 0 !important;
}

.header__login-menu.logged ul li a i,
.header__login-menu.logged ul li a strong {
  margin-left: 5px;
}

.header__login-menu.logged ul li a.img-wrapper {
  opacity: 0.4;
  pointer-events: none;
}
.header__login-menu--mobile {
  display: block;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--iq-white);
  width: 249px;
  height: 100%;
  box-shadow: 0 0 55px rgba(0, 0, 0, 0.15);
  z-index: 10;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: translate(calc(100% + 10px));
  transform: translate(calc(100% + 10px));
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.header__login-menu--mobile.active {
  -webkit-transform: none;
  transform: none;
}

.header__login-menu--mobile .nav-wrapper {
  position: fixed;
  top: 90px;
  bottom: 0;
  overflow: auto;
  width: 249px;
  max-width: 100%;
}

.header__login-menu--mobile nav {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow-y: scroll;
}

.header__login-menu--mobile nav ul {
  -webkit-box-align: start;
  align-items: flex-start;
  padding-left: 0;
  list-style-type: none;
}

.header__login-menu--mobile nav ul,
.header__login-menu--mobile nav ul li {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.header__login-menu--mobile nav ul li {
  width: 100%;
  max-width: 100%;
}

.header__login-menu--mobile nav ul li .accordion-top {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid var(--iq-dullgrey);
}

.header__login-menu--mobile nav ul li .accordion-top i {
  color: var(--iq-pink);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 5px;
}

.header__login-menu--mobile nav ul li .accordion-top.active {
  border-bottom: 1px solid var(--iq-black);
}

.header__login-menu--mobile nav ul li .accordion-top.active i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.header__login-menu--mobile nav ul li .accordion-top.accordion-logged {
  background-color: rgba(21, 168, 224, 0.12);
  border-color: var(--iq-white);
}

.header__login-menu--mobile nav ul li .accordion-top.countries {
  padding: 10px 20px;
}

.header__login-menu--mobile nav ul li .accordion-top.countries img {
  width: 32px;
  max-width: 100%;
  margin-right: 10px;
  margin-bottom: 5px;
}

.header__login-menu--mobile nav ul li .accordion-top.countries a {
  text-transform: uppercase;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: var(--iq-white);
}

.header__login-menu--mobile nav ul li .accordion-top.countries i {
  color: var(--iq-white);
}

.header__login-menu--mobile nav ul li .accordion-top.countries.active {
  border-bottom: 1px solid var(--iq-white);
}

.header__login-menu--mobile nav ul li .accordion-top.currency {
  padding: 10px 20px;
  background: hsla(0, 0%, 78.4%, 0.25);
}

.header__login-menu--mobile nav ul li.active ul {
  display: block;
}

.header__login-menu--mobile nav ul li ul {
  display: none;
}

.header__login-menu--mobile nav ul li ul li {
  padding: 15px 35px;
  border-bottom: 1px solid var(--iq-dullgrey);
}

.header__login-menu--mobile nav ul li ul .accordion-content li:last-child {
  border-bottom: 2px solid var(--iq-dullgrey);
}

.header__login-menu--mobile nav ul li ul.countries--list {
  background-color: #7bb8de;
}

.header__login-menu--mobile nav ul li ul.countries--list li {
  border-bottom: 1px solid var(--iq-white);
  padding: 10px 20px;
}

.header__login-menu--mobile nav ul li ul.countries--list li img {
  width: 32px;
  max-width: 100%;
  margin-right: 10px;
  margin-bottom: 5px;
}

.header__login-menu--mobile nav ul li ul.countries--list li a {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: var(--iq-white);
}

.header__login-menu--mobile nav ul li ul.currency--list li {
  padding: 10px 20px;
}

.header__login-menu--mobile
  .accordion-nested
  .accordion-content
  .accordion-top {
  padding: 15px 20px 15px 35px;
}

.header__login-menu--mobile .accordion-nested .accordion-content ul li {
  padding: 15px 20px 15px 60px;
}

.header__login-menu--button {
  display: block;
  padding: 15px 20px;
  border-bottom: 1px solid var(--iq-dullgrey);
}

.header__login-menu--button.border-none {
  border: none;
}

.header__login-menu--button a {
  display: block;
  margin: 0;
  width: 100%;
  max-width: 100%;
  text-align: left;
}

.header__login-menu--button.primary {
  border-color: var(--iq-white);
}

.header__login-menu--block {
  display: block;
  padding: 15px 20px;
}

.header__login-menu--block p {
  font-size: 17px;
  color: var(--iq-darkblack);
}

.header__login-menu--welcome {
  position: absolute;
  top: -15px;
  color: var(--iq-darkgrey);
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
}

.header__nav {
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.header__nav,
.header__nav--menu {
  display: -webkit-box;
  display: flex;
}

.header__nav--menu {
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.header__nav--menu li {
  position: relative;
}

.header__nav--menu li:not(:last-child) {
  margin-right: 20px;
}

.header__nav--menu li:hover .header__nav--dropdown {
  display: block;
}

.header__nav--menu li:hover .header__nav--dropdown.secondary {
  display: none;
}

.header__nav--menu li:hover i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.header__nav--menu li:hover i.fa-chevron-right {
  -webkit-transform: none;
  transform: none;
}

.header__nav--menu li a {
  color: var(--iq-darkgrey);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__nav--menu li a i {
  color: var(--iq-pink);
  margin-left: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__nav--dropdown {
  position: absolute;
  min-width: 234px;
  max-width: 100%;
  background-color: var(--iq-white);
  border-radius: 0 0 5px 5px;
  display: none;
  padding-top: 20px;
}

.header__nav--dropdown.secondary {
  right: -234px;
  top: 0;
  padding-top: 0;
}

.header__nav--dropdown.secondary ul li a {
  color: var(--iq-darkgrey) !important;
}

.header__nav--dropdown.secondary ul li a:hover {
  color: var(--iq-white) !important;
}

.header__nav--dropdown ul {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  border-top: 1px solid var(--iq-black);
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.header__nav--dropdown ul li {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__nav--dropdown ul li:not(:last-child) {
  margin: 0;
  border-bottom: 1px solid var(--iq-dullgrey);
}

.header__nav--dropdown ul li:last-child {
  border-radius: 0 0 5px 5px;
}

.header__nav--dropdown ul li:hover {
  background-color: var(--iq-pink);
}

.header__nav--dropdown ul li:hover a,
.header__nav--dropdown ul li:hover i {
  color: var(--iq-white);
}

.header__nav--dropdown ul li a {
  padding: 15px 15px 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  text-transform: none !important;
}

.header__nav--dropdown ul li:hover .header__nav--dropdown.secondary {
  display: block;
}

.header__nav--right button.bold-iq {
  font-family: "Swissra-Bold" !important;
  color: var(--iq-white);
}

.page-rtl .header__nav--right button.bold-iq {
  font-family: "Product-Sans-Bold" !important;
}
.header__currency a {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: #f1f1f1;
  padding: 8px;
  border-radius: 5px;
}

.header__currency a i {
  color: var(--iq-pink);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-left: 8px;
}

.header__currency a:hover {
  border-radius: 5px 5px 0 0;
}

.header__currency ul {
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 30px;
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  background-color: #f1f1f1;
  width: 91px;
  border-radius: 0 0 5px 5px;
  margin-bottom: 0;
}

.header__currency ul::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.header__currency ul::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px hsla(0, 0%, 100%, 0.5);
}

.header__currency ul li a,
.header__currency ul li a:hover {
  border-radius: 0;
}

.header__countries {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.header__countries a {
  background-color: var(--iq-pink);
  border-radius: 5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: var(--iq-white);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 147px;
  max-width: 100%;
  padding: 7px 12px;
  text-transform: uppercase;
}

.header__countries a.droplink {
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.header__countries a.droplink img {
  margin: 0;
}

.header__countries a:hover {
  border-radius: 5px 5px 0 0;
}

.header__countries a img {
  max-width: 100%;
  width: 32px;
  margin-right: 8px;
}

.header__countries a i {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__countries ul {
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 30px;
  border-radius: 0 0 5px 5px;
  margin-bottom: 0;
  background-color: var(--iq-pink);
}

.header__countries ul li a,
.header__countries ul li a:hover {
  border-radius: 0;
}

.header__countries ul li:last-child a {
  border-radius: 0 0 5px 5px;
}

.header__cart {
  position: relative;
  border: 1px solid var(--iq-pink);
  border-radius: 5px;
  background-color: var(--iq-pink);
}

.header__cart--content {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.header__cart--icon {
  width: 50px;

  position: relative;
  border-radius: 50%;
}

.header__cart--count,
.header__cart--icon {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__cart--count {
  width: 23px;
  height: 23px;
  position: absolute;
  border-radius: 50%;
  top: -7px;
}

#header .header__cart--count span {
  color: var(--iq-white);
  margin-top: 3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 17px;
}

.page-rtl .header__cart--info {
  padding: 4px 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.header__cart--info {
  background-color: var(--iq-white);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#header .header__cart--info p {
  color: #232f3e;
  font-size: 13px;
}

.header__cart--icon img {
  margin-top: 10px;
}

#header .header__cart--info span {
  font-size: 12px;
  color: var(--iq-black);
}

.header__toggle {
  display: none;
  cursor: pointer;
  position: relative;
  z-index: 999999;
  float: right;
  height: 30px;
  top: 0;
  width: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-left: 22px;
}

.header__wrapper--nav {
  box-shadow: none;
  border-top: 1px solid var(--iq-dullgrey);
}

.header__dropdown {
  position: absolute;
}

.header__dropdown.primary {
  width: 260px;
  top: 20px;
  left: -150px;
  padding-top: 25px;
  display: none;
}

.header__dropdown.primary ul {
  padding: 0;
  list-style-type: none;
  position: relative;
  background-color: var(--iq-white);
  margin-bottom: 0;
  border-radius: 5px;
  z-index: 5;
  display: block;
  box-shadow: 0 -2px 16px rgba(0, 0, 0, 0.06);
}

.header__dropdown.primary ul img {
  position: absolute;
  width: 20px;
  right: 40px;
  top: -12px;
}

.header__dropdown.primary ul li {
  border-radius: 5px;
  margin-right: 0 !important;
}

.header__dropdown.primary ul li:last-child a {
  border-radius: 0 0 5px 5px;
}

.header__dropdown.primary ul li a {
  color: var(--iq-darkblack);
  padding: 13px 20px 10px;
  background-color: var(--iq-white);
  display: block;
  margin-right: 0 !important;
}

.header__dropdown.primary ul li a:hover {
  background-color: var(--iq-pink);
  color: var(--iq-white);
}

.header__dropdown.secondary {
  left: -292px;
  top: 12px;
  padding-top: 25px;
  display: none;
}

.header__balance {
  width: 400px;
  position: relative;
  z-index: 5;
  border: 1px solid var(--iq-dullgrey);
  border-radius: 5px;
}

.header__balance--top {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 20px 20px 15px;
  background-color: var(--iq-white);
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid var(--iq-dullgrey);
}

.header__balance--top p {
  color: var(--iq-darkblack);
}

.header__balance--item {
  background-color: var(--iq-white);
  padding: 15px 20px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.header__balance--item p {
  color: var(--iq-darkblack);
  margin-bottom: 10px;
}

.header__balance--item.last {
  border-top: 1px solid hsla(0, 0%, 76.9%, 0.28);
  border-radius: 0 0 5px 5px;
}

.header__balance--row {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.header__balance--row .btn {
  width: 210px;
  max-width: 100%;
  color: var(--iq-black);
}

.header__balance--row .btn:hover {
  color: var(--iq-black);
  border-color: var(--iq-pink);
}

.header__balance--box {
  width: 140px;
  max-width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: hsla(0, 0%, 78.4%, 0.25);
  border-radius: 5px;
}

.header__balance--box span {
  color: var(--iq-darkblack);
}

.header__wrapper {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: var(--iq-white);
  position: fixed;
  width: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
}
.header__button a {
  color: var(--iq-white);
  border: 1px solid;
  background-color: var(--iq-light-pink);
  padding: 5px 10px;
  border-radius: 5px;
}

.header__toggle div {
  background: var(--iq-pink);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 2px;
  margin: 8px auto;
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__toggle.active div:first-child {
  -webkit-transform: rotate(45deg) translate(5px, 7px);
  transform: rotate(45deg) translate(5px, 7px);
}

.header__toggle.active div:last-child {
  -webkit-transform: rotate(-45deg) translateY(-2px);
  transform: rotate(-45deg) translateY(-2px);
}

.header__wrapper--nav.desktop .header__nav--menu .accordion-content li a {
  text-transform: none;
}
.accordion-content .countries--list li a {
  text-transform: uppercase;
}

.header__wrapper--nav.desktop #buy_now_mobile_highlighted {
  color: var(--iq-white);
}

.header__wrapper--nav.desktop .header__login-menu--button {
  display: block;
  padding: 0;
  border: none;
}

.header__wrapper--nav.desktop .header__login-menu--button a {
  display: block;
  margin: 0;
  width: 100%;
  max-width: 100%;
  text-align: left;
}

.header__wrapper--nav.desktop .header__login-menu--button.primary {
  background-color: var(--iq-pink);
  border-color: var(--iq-dullgrey);
}

.header__wrapper--nav.desktop .header__nav a {
  text-align: left;
  color: var(--iq-brown);
}

.header__wrapper--nav.desktop .header__nav a:hover,
.header__nav--right button:hover {
  color: var(--iq-orange) !important;
}
.header__wrapper--nav.desktop .header__nav--dropdown li a:hover {
  color: var(--iq-white) !important;
}
.header__wrapper--nav.desktop .header__nav--menu li,
.header__wrapper--nav.desktop .header__nav--currency {
  position: relative;
}

.header__wrapper--nav.desktop .header__nav--menu li a {
  text-transform: uppercase;
}

.header__wrapper--nav.desktop .header__nav--wrapper {
  width: 100%;
  max-width: 100%;
}
.header__wrapper--nav.desktop nav {
  width: 100%;
  max-width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.header__wrapper--nav.desktop nav > ul {
  margin-bottom: 0;
}

.header__wrapper--nav.desktop nav ul {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
}

html[lang="ar"] .header__wrapper--nav.desktop nav ul {
  padding-right: 0;
}
.header__wrapper--nav.desktop nav ul li a {
  color: var(--iq-darkgrey);
}

.header__wrapper--nav.desktop nav ul li .accordion-top {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

.header__wrapper--nav.desktop nav ul li .accordion-top i {
  margin-left: 10px;
  color: var(--iq-pink);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 5px;
}

.page-rtl .header__wrapper--nav.desktop nav ul li .accordion-top i {
  margin-right: 10px;
  margin-left: 0;
}
.header__wrapper--nav.desktop nav ul li .accordion-top.active {
  border-bottom: 1px solid var(--iq-black);
}

.header__wrapper--nav.desktop nav ul li .accordion-top.active i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.header__wrapper--nav.desktop nav ul li .accordion-top.currency {
  width: 91px;
  padding: 6px 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: var(--iq-darkblack);
}

.header__wrapper--nav.desktop nav ul li .accordion-top.currency a {
  color: var(--iq-black);
  width: 100%;
  max-width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: flex;
}

.header__wrapper--nav.desktop nav ul li .accordion-top.currency a i {
  margin: 0;
}

.header__wrapper--nav.desktop nav ul li.active ul {
  display: block;
}

.header__wrapper--nav.desktop nav ul li ul {
  display: none;
}

.header__wrapper--nav.desktop nav ul li ul li {
  border-bottom: 1px solid var(--iq-dullgrey);
}
.header__wrapper--nav.desktop nav ul li ul.accordion-content li {
  margin: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__wrapper--nav.desktop nav ul li ul.accordion-content li a {
  display: block;
  width: 100%;
  max-width: 100%;
}

.header__wrapper--nav.desktop
  nav
  ul
  li
  ul.accordion-content
  .accordion-dropdown-second {
  overflow: hidden;
  display: none;
}

.header__wrapper--nav.desktop nav ul li ul.countries--list li {
  border-bottom: none;
}

.header__wrapper--nav.desktop nav ul li ul.countries--list li:first-of-type {
  border-top: none;
}

.header__wrapper--nav.desktop nav ul li ul.countries--list li img {
  width: 32px;
  max-width: 100%;
  margin-right: 14px;
}
.header__wrapper--nav.desktop nav ul li ul.countries--list li a {
  color: var(--iq-white);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  text-transform: uppercase;
}

.header__wrapper--nav.desktop nav ul li ul.currency--list {
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  list-style-type: none;
  display: none;
}
.header__wrapper--nav.desktop
  .accordion-nested
  .accordion-content
  .accordion-top {
  padding: 15px 15px 10px;
}
.header__wrapper--nav.desktop .accordion-nested .accordion-content ul li {
  padding: 0;
}

.header__wrapper--nav.desktop .accordion-nested .accordion-content ul li a {
  padding: 15px 15px 10px;
}
.header__wrapper--nav.desktop
  .accordion-nested
  .accordion-content
  .accordion-nested
  .accordion-top
  a {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.header__wrapper--nav.desktop
  .accordion-nested
  .accordion-content
  .accordion-nested:hover
  .accordion-dropdown-second {
  display: block;
}
.page-rtl .header__wrapper--nav.desktop .header__nav a,
.page-rtl .header__wrapper--nav.desktop .header__nav p {
  text-align: right;
}

.page-rtl .header__wrapper--nav.desktop .header__nav--menu .accordion-top {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-box-align: center;
  align-items: center;
}

.page-rtl .header__wrapper--nav.desktop .header__nav--menu li i {
  margin: 0 10px 0 0;
}

.page-rtl
  .header__wrapper--nav.desktop
  nav
  ul
  li
  ul.accordion-content
  .accordion-dropdown-second {
  right: unset;
  left: -234px;
}

@media (min-width: 992px) {
  .page-rtl .header__wrapper--nav.desktop nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
  }
  .page-rtl
    .header__wrapper--nav.desktop
    .accordion-nested
    .accordion-content
    .accordion-nested
    .accordion-top
    i {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    padding: 0 0 0 10px;
  }
  .page-rtl .header__wrapper--nav.desktop .header__nav--menu li:hover ul {
    right: 0;
  }
  .header__wrapper--nav.desktop nav ul li ul.currency--list li {
    border-bottom: none;
  }
  .header__wrapper--nav.desktop nav ul li ul.currency--list li:first-of-type {
    border-top: none;
  }
  .header__wrapper--nav.desktop nav ul li ul.currency--list li:hover {
    background-color: #f1f1f1 !important;
  }

  .header__wrapper--nav.desktop nav ul li ul.currency--list li:hover a {
    color: var(--iq-black) !important;
  }
  .header__wrapper--nav.desktop nav ul li ul.currency--list li a {
    width: 91px;
    padding: 8px 10px 4px;
    background-color: #f1f1f1;
    border-radius: 5px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: var(--iq-darkblack);
  }
  .header__inner--right.mobile,
  .header__login-menu--mobile,
  .header__wrapper--nav.desktop nav ul .hide-on-desktop {
    display: none;
  }
  .header__wrapper--nav.desktop nav ul li ul.currency--list {
    position: absolute;
    top: 30px;
    max-height: 200px;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #f1f1f1;
    width: 91px;
    border-radius: 0 0 5px 5px;
    margin-bottom: 0;
  }

  .header__wrapper--nav.desktop nav ul li ul.currency--list::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  .header__wrapper--nav.desktop
    nav
    ul
    li
    ul.currency--list::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px hsla(0, 0%, 100%, 0.5);
  }
  .header__wrapper--nav.desktop nav ul li ul.countries--list li a {
    width: 155px;
    padding: 8px 10px 4px;
    background-color: var(--iq-white) !important;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: unset;
  }
  .header__wrapper--nav.desktop nav ul li ul.countries--list li:last-child a {
    border-radius: 0 0 5px 5px;
  }
  .header__wrapper--nav.desktop .header__nav--menu li:hover ul {
    position: absolute;
    min-width: 234px;
    max-width: 100%;
    background-color: var(--iq-white);
    border-radius: 0;
    display: block;
    border: 1px solid #857c7c;
  }
  .header__wrapper--nav.desktop .header__nav--menu li:hover ul ul {
    padding: 0;
  }
  .header__wrapper--nav.desktop .header__nav--currency:hover .currency--list {
    display: block;
  }

  .header__wrapper--nav.desktop .header__nav--currency:hover .currency i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .header__wrapper--nav.desktop nav ul li ul.accordion-content li {
    border-bottom: 1px solid var(--iq-orange);
  }
  .header__wrapper--nav.desktop nav ul li ul.accordion-content li:last-child {
    border-bottom: none;
  }
  .header__wrapper--nav.desktop nav ul li ul.accordion-content li a {
    padding: 15px 15px 10px;
  }
  .header__wrapper--nav.desktop
    nav
    ul
    li
    ul.accordion-content
    li:not(.accordion-nested):hover
    a {
    color: var(--iq-white);
  }
  .header__wrapper--nav.desktop
    nav
    ul
    li
    ul.accordion-content
    li:last-child:hover {
    border-radius: 0 0 5px 5px;
  }
  .header__wrapper--nav.desktop
    nav
    ul
    li
    ul.accordion-content
    .accordion-dropdown-second {
    position: absolute;
    right: -234px;
    top: 0;
    bottom: unset;
    padding-top: 0;
  }
  .header__wrapper--nav.desktop
    nav
    ul
    li
    ul.accordion-content
    .accordion-dropdown-second
    li {
    border-top: none;
  }
  .header__wrapper--nav.desktop nav ul li ul.countries--list {
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    list-style-type: none;
    display: none;
    position: absolute;
    top: 46px;
    border-radius: 0 0 5px 5px;
    margin-bottom: 0;
    border: 1px solid var(--iq-orange);
    margin-top: 25px;
  }
  .header__wrapper--nav.desktop
    .accordion-nested
    .accordion-content
    .accordion-nested:hover
    .accordion-top
    a,
  .header__wrapper--nav.desktop
    .accordion-nested
    .accordion-content
    .accordion-nested:hover
    .accordion-top
    a
    i {
    color: var(--iq-black);
  }
  .header__wrapper--nav.desktop
    .accordion-nested
    .accordion-content
    .accordion-nested
    .accordion-top {
    padding: 0 10px 0 0;
  }
  .header__wrapper--nav.desktop
    .accordion-nested
    .accordion-content
    .accordion-nested
    .accordion-top
    i {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
@media (max-width: 991px) {
  .header {
    padding-bottom: 0;
  }
  .page-rtl
    .header__wrapper--nav.desktop
    .header__nav--menu
    li:not(:last-child) {
    margin-left: 0;
  }
  .page-rtl .header__wrapper--nav.desktop .header__nav {
    -webkit-transform: translate(-400%);
    transform: translate(-400%);
    right: unset;
  }
  .page-rtl .header__wrapper--nav.desktop .header__nav.active {
    left: 0;
    right: unset;
    -webkit-transform: none;
    transform: none;
  }
  .header__wrapper--nav.desktop
    .accordion-nested
    .accordion-content
    .accordion-nested
    .accordion-top
    a {
    width: unset;
  }
  .header__wrapper--nav.desktop .accordion-nested .accordion-content ul li {
    padding: 15px 20px 15px 60px;
  }
  .header__wrapper--nav.desktop
    .accordion-nested
    .accordion-content
    .accordion-nested,
  .header__wrapper--nav.desktop .accordion-nested .accordion-content ul li a {
    padding: 0;
  }
  .header__wrapper--nav.desktop
    .accordion-nested
    .accordion-content
    .accordion-top {
    padding: 15px 20px 15px 35px;
  }
  .header__wrapper--nav.desktop nav ul li ul.currency--list li a {
    padding: 0;
  }
  .header__wrapper--nav.desktop nav ul li ul.currency--list li {
    padding: 10px 20px;
  }
  .header__wrapper--nav.desktop nav ul li ul.countries--list li a {
    padding: 0;
  }
  .header__wrapper--nav.desktop nav ul li ul.countries--list li {
    border-bottom: 1px solid var(--iq-white);
    padding: 10px 20px;
  }
  .header__wrapper--nav.desktop nav ul li ul li {
    padding: 15px 30px;
  }
  .header__wrapper--nav.desktop nav ul li .accordion-top.currency {
    padding: 10px 20px;
    background: hsla(0, 0%, 78.4%, 0.25);
    width: 100%;
    max-width: 100%;
    border-radius: 0;
  }
  .header__wrapper--nav.desktop nav ul li .accordion-top.accordion-logged {
    background-color: rgba(21, 168, 224, 0.12);
    border-color: var(--iq-white);
  }
  .header__wrapper--nav.desktop nav ul li .accordion-top.accordion-logged a {
    color: var(--iq-pink);
  }
  .header__wrapper--nav.desktop nav ul li .accordion-top i {
    margin: 0;
  }
  .header__wrapper--nav.desktop nav ul li .accordion-top {
    padding: 15px 20px;
    border-bottom: 1px solid var(--iq-dullgrey);
  }
  .header__wrapper--nav.desktop nav ul li {
    width: 100%;
    max-width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
  .header__wrapper--nav.desktop nav ul .hide-on-mobile {
    display: none;
  }
  .header__wrapper--nav.desktop nav ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
    padding-left: 0;
    list-style-type: none;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }

  html[lang="ar"] .header__wrapper--nav.desktop nav ul {
    padding-right: 0;
  }
  .header__wrapper--nav.desktop nav > ul {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow-y: scroll;
  }
  .header__wrapper--nav.desktop nav {
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    overflow-y: scroll;
  }
  .header__wrapper--nav.desktop .header__nav--wrapper {
    overflow-y: scroll;
    position: fixed;
    top: 90px;
    bottom: 0;
    overflow: auto;
    width: 249px;
    max-width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: unset;
    justify-content: unset;
  }
  .header__wrapper--nav.desktop .header__nav--menu li:hover i {
    -webkit-transform: none;
    transform: none;
  }
  .header__wrapper--nav.desktop .header__nav--currency {
    width: 100%;
    max-width: 100%;
  }
  .header__wrapper--nav.desktop .header__nav.active {
    -webkit-transform: none;
    transform: none;
  }
  .header__wrapper--nav.desktop .container {
    padding: 0;
  }
  .header__wrapper--nav.desktop .header__login-menu--button {
    padding: 15px 20px;
    border-bottom: 1px solid var(--iq-dullgrey);
  }
  .header__wrapper {
    position: unset;
    width: unset;
    top: unset;
    left: unset;
    max-width: unset;
  }
  .header__inner--right.desktop,
  .header__login-menu:after,
  .header__wrapper--nav.desktop {
    display: none;
  }
  .header__login-menu--welcome {
    padding: 10px 20px;
    position: static;
    margin-right: 15px;
    font-size: 17px;
    width: auto;
  }
  .header__login-menu--welcome:after {
    right: 15px;
  }
  .header__toggle,
  .header__wrapper--nav.desktop {
    display: block;
  }
  .header__nav--right li button.bold-iq {
    color: var(--iq-black);
    padding: 10px 0 0 20px;
    text-align: left;
    font-size: 17px;
  }
  .page-rtl .header__nav--right li button.bold-iq {
    padding: 10px 20px 0 0;
    text-align: right;
  }
  .header__wrapper--nav.desktop .header__nav {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--iq-white);
    width: 249px;
    height: 100%;
    box-shadow: 0 0 55px rgba(0, 0, 0, 0.15);
    z-index: 10;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translate(calc(100% + 10px));
    transform: translate(calc(100% + 10px));
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
  .header__wrapper--nav.desktop .header__nav--menu {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
    padding-left: 0;
    list-style-type: none;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
}
@media (max-width: 767px) {
  .header__inner--logo {
    width: 200px;
  }
}

@media (max-width: 575px) {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    margin-bottom: 0px;
    z-index: 100;
  }
  .header__toggle {
    height: 25px;
    width: 25px;
  }

  .header__button a {
    font-size: 12px;
  }
}
@media (max-width: 479px) {
  .header__inner {
    padding: 5px 0;
  }
  .header__inner--logo {
    width: 130px;
    padding-bottom: 0px;
  }
}
